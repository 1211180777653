import { blastAssetsApi } from "../blastAssetsApi";
import { z } from "zod";

const EmojiSchema = z.object({
  id: z.string(),
  name: z.string(),
  orderIndex: z.number(),
  isUnlockOnly: z.boolean().optional(),
  code: z.string(),
  hidden: z.boolean().optional(),
  score: z.number().optional(),
  isUnlocked: z.boolean().optional(),
});

export type Emoji = z.infer<typeof EmojiSchema>;

export const getEmojis = async (): Promise<Emoji[]> => {
  const { data } = await blastAssetsApi.get<unknown>(`/emojis/index.json`);
  return z.array(EmojiSchema).parse(data);
};
