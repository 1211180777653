import axios from "axios";
import { debugLoggingInterceptor } from "@portal-frontend-ssr/blast-api";
import { getConfig } from "@portal-frontend-ssr/config";

const blastAssetsApi = axios.create({
  baseURL: getConfig().BASE_ASSET_URL,
});

blastAssetsApi.interceptors.response.use((response) => response, debugLoggingInterceptor);

export { blastAssetsApi };
